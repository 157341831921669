<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiGasAlarmId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 가스누출감지 경보기 설치 상세정보 -->
          <c-card title="LBL0010543" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiGasAlarmId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="LBL0010565"
                itemText="revisionNum"
                itemValue="psiGasAlarmId"
                name="selectedpsiGasAlarmId"
                label=""
                v-model="selectedpsiGasAlarmId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{ $label('LBL0010385') }}
                    </div>
                    <div class="tooltipCustom">
                    <!-- ① 감지대상은 감지하고자 하는 물질을 기재합니다. -->
                    {{ $message('MSG0010054') }}<br>
                    <!-- ② 설치장소는 구체적인 화학설비 및 부속설비의 주변 등으로 구체적으로 기재합니다. -->
                    {{ $message('MSG0010055') }}<br>
                    <!-- ③ 경보설정치는 폭발하한계(LEL)의 25% 이하, 허용농도 이하 등으로 기재합니다. -->
                    {{ $message('MSG0010056') }}<br>
                    <!-- ④ 경보시 조치내용은 경보가 발생할 경우 근로자의 조치내용을 기재합니다. -->
                    {{ $message('MSG0010057') }}<br>
                    <!-- ⑤ 유지관리에는 교정 주기 등을 기재합니다. -->
                    {{ $message('MSG0010058') }}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 개정 -->
                <c-btn
                  v-show="editable && popupParam.psiGasAlarmId && !isRevision"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="editable && popupParam.psiGasAlarmId && isRevision"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 삭제 -->  
                <c-btn
                  v-if="editable && popupParam.psiGasAlarmId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <!-- 저장 -->  
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <!-- 가스감지기NO -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010533"
                  name="gasDetectorNo"
                  v-model="data.gasDetectorNo">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 감지대상 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010534"
                  name="sensingObject"
                  v-model="data.sensingObject">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 설치장소 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010553"
                  name="installLocation"
                  v-model="data.installLocation">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <!-- 작동시간 -->
                <c-text
                  :editable="editable"
                  label="LBL0010535"
                  name="operationTime"
                  v-model="data.operationTime">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 측정방식 -->
                <c-text
                  :editable="editable"
                  label="LBL0010536"
                  name="detectMethod"
                  v-model="data.detectMethod">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 경보설정값-high -->
                <c-text
                  :editable="editable"
                  label="LBL0010544"
                  name="alarmHighValue"
                  v-model="data.alarmHighValue">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 경보설정값-highhigh -->
                <c-text
                  :editable="editable"
                  label="LBL0010545"
                  name="alarmHighhighValue"
                  v-model="data.alarmHighhighValue">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 경보기설치위치 -->
                <c-text
                  :editable="editable"
                  label="LBL0010538"
                  name="alarmSettingLocation"
                  v-model="data.alarmSettingLocation">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 정밀도 -->
                <c-text
                  :editable="editable"
                  label="LBL0010539"
                  name="precisionVal"
                  v-model="data.precisionVal">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 조치내용 -->
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="LBL0010540"
                  name="measureInfo"
                  v-model="data.measureInfo">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 유지관리 -->
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="LBL0010541"
                  name="management"
                  v-model="data.management">
                </c-textarea>
              </div>
              <div class="col-12">
                <!-- 비고 -->
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="LBLREMARK"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="LBL0001705"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-6">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="LBL0001721"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'gas-leak-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiGasAlarmId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedpsiGasAlarmId: null,
      saveUrl: transactionConfig.psi.lbe.gasalarm.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        psiGasAlarmId: '',  // 가스누출감지경보기 일련번호
        plantCd: '',  // 사업장코드
        gasDetectorNo: '',  // 가스감지기NO
        sensingObject: '',  // 감지대상
        installLocation: '',  // 설치장소
        operationTime: '',  // 작동시간
        detectMethod: '',  // 측정방식
        alarmHighValue: '',  // 경보설정값-high
        alarmHighhighValue: '',  // 경보설정값-highhigh
        alarmSettingLocation: '',  // 경보기설치위치
        precisionVal: '',  // 정밀도
        measureInfo: '',  // 조치내용
        management: '',  // 유지관리
        remark: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.lbe.gasalarm.get.url;
      this.insertUrl = transactionConfig.psi.lbe.gasalarm.insert.url;
      this.updateUrl = transactionConfig.psi.lbe.gasalarm.update.url;
      this.deleteUrl = transactionConfig.psi.lbe.gasalarm.delete.url;
      this.revlistUrl = selectConfig.psi.lbe.gasalarm.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiGasAlarmId) {
        this.getDetail(this.popupParam.psiGasAlarmId, true);
        this.selectedpsiGasAlarmId = this.popupParam.psiGasAlarmId
      }
    },
    getDetail(_psiGasAlarmId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiGasAlarmId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiGasAlarmId == this.popupParam.psiGasAlarmId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiGasAlarmId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiGasAlarmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiGasAlarmId = result.data.psiGasAlarmId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedpsiGasAlarmId = row;
        if (this.selectedpsiGasAlarmId != this.data.psiGasAlarmId) {
          this.getDetail(this.selectedpsiGasAlarmId, false);
        }
      }
    },
  }
};
</script>
